<template>
  <div class="wrap-content">
    <aside>
      <ul>
        <li v-for="(item, i) in nav" :key="i" @click="handleNav(item.path)" :class="{ active: item.isSelect }">
          <img :src="item.default" alt="" v-if="!item.isSelect" />
          <img :src="item.select" alt="" v-if="item.isSelect" />
          <p>{{ item.title }}</p>
        </li>
      </ul>
    </aside>
    <section>
      <router-view />
    </section>
  </div>
</template>
    
<script setup>
import { ref, inject, provide, onMounted } from "vue";
import { useRouter } from "vue-router";
// import common from "./common.vue";
// import important from "./important.vue";
// import Header from "./components/Header.vue";
// import Footer from "@/components/footer.vue";
// import noData from "./components/noData.vue";
import bus from "@/utils/bus.js";
let tab = ref(0);
const handleSubNav = (val) => {
  tab.value = val;
};

let nav = ref([
  {
    title: "机构管理",
    path: "orgManage",
    key: "/org",
    isSelect: true,
    default: require("../../assets/img/机构管理-默认.png"),
    select: require("../../assets/img/机构管理-选中.png"),
  },
  {
    title: "用户管理",
    key: "/user",
    path: "userManage",
    isSelect: false,
    default: require("../../assets/img/用户管理-默认.png"),
    select: require("../../assets/img/用户管理-选中.png"),
  },
  {
    title: "分类配置",
    path: "classifyManage",
    key: "/classify",
    isSelect: false,
    default: require("../../assets/img/分类配置-默认.png"),
    select: require("../../assets/img/分类配置-选中.png"),
  },
  {
    title: "填报设置",
    path: "setupManage",
    key: "/setup",
    isSelect: false,
    default: require("../../assets/img/填报设置-默认.png"),
    select: require("../../assets/img/填报设置-选中.png"),
  },
  {
    title: "填报情况",
    path: "situationManage",
    key: "/situation",
    isSelect: false,
    default: require("../../assets/img/填报情况-默认.png"),
    select: require("../../assets/img/填报情况-选中.png"),
  },
]);

const router = useRouter();
const handleNav = (path) => {
  router.push({
    name: path,
  });

  setTimeout(() => {
    renderMenu();
  }, 300);
};
let path = ref("/");
const renderMenu = () => {
  path.value = window.location.pathname;
  nav.value = nav.value.map((item) => {
    if (path.value.includes(item.key)) {
      item.isSelect = true;
    } else {
      item.isSelect = false;
    }
    return item;
  });
};
onMounted(() => {
  renderMenu();
});
</script>
    
<style lang="scss" scoped>
.wrap-content {
  padding: 0 40px;
  background: #fff;
  min-height: 100vh;
  display: flex;
  height: 100%;
}

.noData {
  height: calc(100vh - 100px);
}

aside {
  width: 200px;
  padding-top: 80px;
  border-right: 1px solid #eeeeee;
  ul {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      margin-bottom: 30px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        width: 16px;
        height: 16px;
        margin: 0 auto;
      }

      p {
        white-space: nowrap;
        line-height: 30px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #111111;
        margin-left: 15px;
      }
    }

    li.active {
      p {
        color: #c12a21;
        font-weight: bold;
      }
    }
  }
}

section {
  width: calc(100% - 250px);
  margin-left: 50px;
}
</style>